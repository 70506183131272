import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Navbar, Nav, Container } from "react-bootstrap";
import {navigate} from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import "./Nav.scss"

const MyNav = ({siteTitle, location, layout}) => {
  const data = useStaticQuery(graphql`
    query {
      entries: allMdx(sort: {fields: [frontmatter___weight], order: ASC}, filter: {frontmatter: {menu: {eq: "main"}}}) {
        edges {
          node {
            slug
            fields {
              slug
            }
            frontmatter {
              title
              type
              layout
            }
          }
        }
      }
    }
  `);

  const handleClickItem = (evt,slug) => {
    evt.preventDefault();
    if(slug.indexOf('#') === 0){
      scrollTo(slug);
    }else{
      navigate(slug);
    }
  }

  const entries = data.entries.edges;
  return (
    <Navbar id="mainNav" expand="lg" className="navbar fixed-top" collapseOnSelect>
      <Container fluid className="container">
        <Navbar.Brand as={Link} to={location.pathname === '/' ? "#section0" : '/'} onClick={(e) => handleClickItem(e,location.pathname === '/' ? "#section0" : '/')}>
          {siteTitle}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav className="mr-auto justify-content-between">
            {entries.map((entry, i) => {
              const solutionsSubsection = layout === 'solutions' && entry.node.frontmatter.layout === 'section-solutions';
              const slug = entry.node.frontmatter.type === 'sections' && location.pathname === '/' ? 
              `#${entry.node.slug}` : 
              entry.node.fields?.slug ? entry.node.fields.slug : `/#${entry.node.slug}`;
            return (
              <Nav.Link 
                activeClassName="active"
                active={solutionsSubsection}
                key={i} 
                as={Link} 
                to={slug}
                onClick={(e) => handleClickItem(e,slug)}>
                {solutionsSubsection && `< `}{entry.node.frontmatter.title}
              </Nav.Link>
            )})}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNav;