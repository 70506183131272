import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, ButtonGroup, Button } from "react-bootstrap";


export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      entries: allMdx(sort: {fields: [frontmatter___weight], order: ASC}, filter: {frontmatter: {menu: {eq: "footer"}}}) {
        edges {
          node {
            slug
            fields {
              slug
            }
            frontmatter {
              title
              type
            }
          }
        }
      }
    }
  `);
  const entries = data.entries.edges;
  return (
    <footer className="Footer">
      <Container id="footer-menu" className="mx-auto text-center">
        <ButtonGroup>
          {entries.map((entry,i) => (
            <Button key={i} variant="secondary">
              <Link to={entry.node.fields.slug}>
                {entry.node.frontmatter.title}
              </Link>
            </Button>
          ))}
        </ButtonGroup>
      </Container>
      <div className="container text-center">
        <p>
          Copyright © 2020 BD Finances &amp; Patrimoine. All Rights Reserved. 
          <a className="author-link" title="Réalisation 4LW" href="https://4lw.fr">
            4LW
          </a>
        </p>
      </div>
    </footer>
  )
};

export default Footer;