/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import {DataProvider} from "context/dataContext";
import Nav from "components/Nav"
import './layout.scss'
import Footer from "../components/Footer";
import Notifications from "../components/Notifications";

const Layout = ({ children, pageContext, location }) => {
  const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix" )
    .map(icon => Icons[icon]);

  library.add(...iconList);
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: {sourceInstanceName: {eq: "sections"}, extension: {ne: "md"}}) {
        nodes {
          childImageSharp {
            gatsbyImageData(width: 800)
          }
          relativePath
        }
      }
    }
  `);

  const { allFile: {nodes: sectionImages}} = data;
  const images = [...(pageContext.images || []), ...sectionImages.map(i => ({
    relativePath: i.relativePath,
    image: i.childImageSharp
  }))];
  

  return (
    <DataProvider value={{
      images
    }}>
      <Nav id="page-top" siteTitle={data.site.siteMetadata.title} location={location} layout={pageContext.layout} />
        {children}
      <Footer />
      <Notifications />
    </DataProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
