import React from "react";
import {Link} from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MdxFigure from "components/Mdx/MdxFigure";
import MdxForm from "components/Mdx/MdxForm";
import MdxFormItem from "components/Mdx/MdxForm/MdxFormItem";
import ImgKpi from "components/Editor/ImgKpi";
import Kpis from "components/Editor/Kpis";
import { MDXProvider } from "@mdx-js/react";

export const wrapRootElement = ({element}) => {
  const shortcodes = {Link, Kpis, ImgKpi, FontAwesomeIcon, MdxFigure, MdxForm, MdxFormItem};
  return (
    <MDXProvider components={{...shortcodes}}>
      {element}
    </MDXProvider>
  );
};