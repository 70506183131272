import React from "react"
import { Form, Button, Alert } from 'react-bootstrap'
import * as qs from "query-string"
import MdxFormItem from "./MdxFormItem";
import "./MdxForm.scss";

const MdxForm = ({success, error, submit, name, children, location}) => {
  const isBrowser = typeof window !== `undefined`;
  // formFields is a string when used in mdx shortcodes
  const fieldsDefinition = children.map((comp) => {
    if(comp.props && comp.props.mdxType === 'MdxFormItem'){
      return {
        ...comp.props,
      };
    }
    return false;
  }).filter(a => a);
  const fieldsDefault = {};
  const pathname = location ? location.pathname : isBrowser && window.location.pathname;
  const locationValues = location ? qs.parse(location.search) : isBrowser && qs.parse(window.location.search);
  fieldsDefinition.map((field) => {
    fieldsDefault[field.name] = locationValues[field.name] ? locationValues[field.name] : field.default;
    return null;
  });

  const [validated, setValidated] = React.useState(false);
  const [fields, setFields ] = React.useState(fieldsDefault);
  const [ feedback, setFeedback] = React.useState(null);
  const [ processing, setProcessing] = React.useState(false);

  const handleChangeField = (name) => (evt) => {
    fields[name] = evt.target.value || '';
    setFields({...fields});
  };

  const handleSubmit = async (event) => {
    console.log("submit", event);
    const form = event.currentTarget;
    setProcessing(true);
    event.preventDefault();
    const formData = {...fields, "form-name": name};
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      //Send value via netlify
      const queryOptions = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: qs.stringify(formData),
      }
      fetch(pathname,queryOptions)
        .then(response => {
          if(response.status !== 200){
            throw new Error(response.statusText);
          }
          setFeedback({status: 'success', message: success});
          form.reset()
        })
        .catch(err =>
          setFeedback({status: 'danger', message: error}));
    }
    setValidated(true);
    setProcessing(false);
  };
  return (
    <Form id="contact-form" className="contact-form" name={name} noValidate netlify-honeypot="bot-field" action={pathname} validated={validated} onSubmit={handleSubmit} data-netlify="true">
      {feedback && (<Alert variant={feedback.status}>{feedback.message}</Alert>)}
      <input type="hidden" name="bot-field" />
      {fieldsDefinition.map((field, key) => (
        <MdxFormItem 
          key={key} 
          index={key}
          name={field.name}
          value={fields[field.name]}
          label={field.label}
          handleChangeField={handleChangeField(field.name)}
          type={field.type}
          placeholder={field.placeholder}
          required={field.required}
          feedback={field.feedback}
        />
      ))}
      <Form.Row className="align-right" style={{textAlign: "right"}}>
        <Button disabled={processing} type="submit">{submit}</Button>
      </Form.Row>
    </Form>
  );
};

MdxForm.defaultProps = {
  success: "Merci pour votre message.",
  error: "Une erreur s'est produite lors de l'envoi du message.",
  submit: "Envoyer",
};

export default MdxForm;