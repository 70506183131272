import React from "react"
import DataContext from "context/dataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Figure } from "react-bootstrap";
import "./MdxFigure.scss";

const MdxFigure = ({title, src, alt}) => {
  return (
    <DataContext.Consumer>
      {context => {
        const img = getImage(context.images.find(i => {
          return i.relativePath === src;
        })?.image);
      return (
          <Figure className="MdxFigure portrait">
            <GatsbyImage 
              image={img} 
              className="figure-img" 
              title={title}
              alt={alt}/>
            <Figure.Caption>
              <h4>
                {title}
              </h4>
            </Figure.Caption>
          </Figure>
        );
      }}
      </DataContext.Consumer>
  );
};

export default MdxFigure;
