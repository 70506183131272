import React from "react";

export const DataContext = React.createContext();

const DataProvider = ({children, value}) => {
  return (
    <DataContext.Provider 
    value={{
      ...value
    }}
    >
      {children}
    </DataContext.Provider>
  )
};

export default DataContext
export { DataProvider }