import React from "react";
import { Form, InputGroup, Col } from "react-bootstrap";


const MdxFormItem = ({
  index, 
  name, 
  value, 
  label, 
  handleChangeField, 
  type, 
  placeholder, 
  required, 
  feedbackInvalid,
  feedbackValid
}) => {
  return (
    <>
      {type === 'hidden' ? (
        <input type="hidden" name={name} value={value} />
      ) : (
        <Form.Group as={Col} md="12" controlId={`customValidation${index}`}>
          <Form.Label>{label}</Form.Label>
          {type === 'textarea' && (
            <Form.Control as="textarea" rows="3" name={name}
            value={value}
            onChange={handleChangeField}
            />
          )}
          {type !== 'textarea' && (
              <InputGroup>
                <Form.Control
                  required={required}
                  type={type}
                  name={name}
                  value={value}
                  onChange={handleChangeField}
                  placeholder={placeholder}
                />
                {feedbackInvalid && (
                  <Form.Control.Feedback type="invalid">
                    {feedbackInvalid}
                  </Form.Control.Feedback>
                )}
                {feedbackValid && (
                  <Form.Control.Feedback type="valid">
                    {feedbackValid}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
          )}
        </Form.Group>
      )}
    </>
  );
};

export default MdxFormItem;