import React from "react"
import DataContext from "context/dataContext";
import {GatsbyImage, getImage} from "gatsby-plugin-image"

export const ImgKpi = ({src, caption}) => {
  return (
    <DataContext.Consumer>
      {context => {
        const img = getImage(context.images.find(i => {
          return i.relativePath === src;
        })?.image);
        return (
          <li>
            <div className="img">
              <GatsbyImage 
                className=""
                alt={caption}
                image={img}/>
            </div>
            <div className="caption">
              {caption}
            </div>
          </li>
        );
    }}
    </DataContext.Consumer>
  )
}

export default ImgKpi;