import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby";
import { Toast } from "react-bootstrap";
import { asyncLocalStorage } from "utils/storage";
import { getCurrentDate, getFrenchDate } from "utils/dates";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDXRenderer } from "gatsby-plugin-mdx"

import "./Notifications.scss";

const NOTIFICATIONS_KEY = "notifications";
const NOTIFICATIONS_DELAY = 5000;

const Notifications = () => {
  const {allMdx: {edges} } = useStaticQuery(graphql`
    query Notifications {
      allMdx(
        filter: {frontmatter: {type: {eq: "notification"}}, fields: {displayable: {eq: true}}}
        sort: {fields: frontmatter___date_from, order: DESC}
      ) {
        edges {
          node {
            id
            body
            frontmatter {
              title
              date_from(formatString: "YYYY-MM-DD")
              date_to(formatString: "YYYY-MM-DD")
              link
              linkLabel
            }
          }
        }
      }
    }
  `);
  const frenchDate = getFrenchDate();
  const [notifData, setNotifData] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const [displayItem, setDisplayItem] = React.useState(null);

  const handleOnClose = (item) => {
    setShow(false);
    setNotifData((prev) => ({
      ...prev,
      seen:[
        ...prev?.seen || [],
        item.id
      ]
    }));
  }

  const handleOnClick = (item) => {
    handleOnClose(item);
    //Track
    if(typeof window !== 'undefined' &&
    typeof window.gtag !== "undefined"){
      window.gtag("event", "select_content", {
        content_type: "notification",
        content_id: item.id,
        items: [{
          name: item.title,
        }]
      });
    }
  }

  React.useEffect(() => {
    const fetchNotifData = async () => {
      const nd = await asyncLocalStorage.getItem(NOTIFICATIONS_KEY);
      if(nd){
        try{
          const parsed = JSON.parse(nd);
          setNotifData(parsed);
        }catch (error){
          console.error(error);
        }
      }
    }
    const currentDate = getCurrentDate();
    setItems(edges.map((edge) => ({
      id: edge.node.id,
      body: edge.node.body,
      title: edge.node.frontmatter.title,
      from: edge.node.frontmatter.date_from,
      to: edge.node.frontmatter.date_to,
      link: edge.node.frontmatter.link,
      linkLabel: edge.node.frontmatter.linkLabel,
    })).filter(item => (currentDate >= item.from && currentDate <= item.to)));
    fetchNotifData().then(() => {
      setLoading(false);
    });
  },[]);

  React.useEffect(() => {
    if(!loading && !show){
      const source = notifData?.seen?.length > 0 ? items.filter(item => notifData.seen.indexOf(item.id) === -1) : items;
      setDisplayItem(source.length ? source.pop() : null);
    }
  },[notifData, loading, items]);

  React.useEffect(() => {
    if(displayItem){
      setShow(true);
    }
  }, [displayItem]);

  React.useEffect(() => {
    if(notifData && Object.keys(notifData).length > 0){
      asyncLocalStorage.setItem(NOTIFICATIONS_KEY, JSON.stringify(notifData));
    }
  }, [notifData]);

  return (
    <div className="Notifications">
      {displayItem !== null && (
        <Toast show={show} onClose={() => handleOnClose(displayItem)}>
          <Toast.Header>
            <FontAwesomeIcon 
              icon={`bell`}
            />&nbsp;
            <strong className="mr-auto">{displayItem.title}</strong>
            <small>{frenchDate}</small>
          </Toast.Header>
          <Toast.Body>
            <MDXRenderer>
              {displayItem.body}
            </MDXRenderer>
            <Link 
              className="btn btn-primary" 
              to={displayItem.link} 
              onClick={() => handleOnClick(displayItem)}>
              {displayItem.linkLabel}
            </Link>
          </Toast.Body>
        </Toast>
      )}
    </div>
  )
};

export default Notifications;