import React from "react";


export const Kpis = ({children, type}) => {
  return (
    <ul className={`kpis ${type}`}>
      {children}
    </ul>
  )
};

export default Kpis;